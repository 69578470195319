<template>
    <div class="container-fluid">
        <Dialog :style="{ width: '35rem' }" @hide="cerrarDialogCrear" header="Nueva documentación" modal
        v-model:visible="visibleDialogCrear">
            <div style="display: grid; gap: 10px;">
                <div class="form-group">
                    <label for="">Nombre</label>
                    <input class="form-control" type="text" v-model="nombreDocuNueva" name="" id="">
                </div>
                <div class="form-group">
                    <label for="">Tipo</label>
                    <select class="form-control" v-model="tipoDocuNueva" id="">
                        <option value="Sin vencimiento">Sin vencimiento</option>
                        <option value="Anual">Anual</option>
                        <option value="Semestral">Semestral</option>
                        <option value="Trimestral">Trimestral</option>
                        <option value="Mensual">Mensual</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Día del mes de vencimiento</label>
                    <input class="form-control" type="number" v-model="diaDocuNueva" max="31" id="">
                </div>
                <div v-if="tipoDocuNueva=='Anual'" class="form-group">
                    <label for="">Mes de vencimiento</label>
                    <select class="form-control" v-model="mesDocuNueva" id="">
                        <option value="0">---</option>
                        <option value="1">Enero</option>
                        <option value="2">Febrero</option>
                        <option value="3">Marzo</option>
                        <option value="4">Abril</option>
                        <option value="5">Mayo</option>
                        <option value="6">Junio</option>
                        <option value="7">Julio</option>
                        <option value="8">Agosto</option>
                        <option value="9">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>
                <div style="display: flex; gap: 5px;">
                    <input type="checkbox" v-model="avisarDocuNueva" v-bind:true-value="1" v-bind:false-value="0">
                    <span>Avisar</span>
                </div>
                <div v-if="avisarDocuNueva == '1'" style="display: grid; gap: 10px;">
                    <div class="form-group">
                        <label for="">Días de antelación para aviso</label>
                        <input class="form-control" type="number" max="365" v-model="diasAvisoDocuNueva" id="">
                    </div>
                    <div class="form-group">
                        <label for="">Asunto email</label>
                        <input class="form-control" type="text" v-model="asuntoEmailDocuNueva" id="">
                    </div>
                    <div class="form-group">
                        <label for="">Texto email</label>
                        <textarea class="form-control" v-model="textoEmailDocuNueva" id=""></textarea>
                    </div>
                    <p> Palabras claves: {fecha_vencimiento} {nombre_documento} </p>
                </div>
                <div>
                    <button @click="guardarNuevaDocu" class="btn btn-sm btn-light" style="border: 1px solid grey;">Guardar</button>
                </div>
            </div>
        </Dialog>
        <Dialog :style="{ width: '35rem' }" @hide="cerrarDialogEditar" header="Editar documentación" modal
         v-model:visible="visibleDialogEditar">
            <div style="display: grid; gap: 10px;">
                <div class="form-group">
                    <label for="">Nombre</label>
                    <input class="form-control" type="text" v-model="documentoAEditar.nombre_documentacion" name="" id="">
                </div>
                <div class="form-group">
                    <label for="">Tipo</label>
                    <select class="form-control" v-model="documentoAEditar.tipo_vencimento_documentacion" id="">
                        <option value="Sin vencimiento">Sin vencimiento</option>
                        <option value="Anual">Anual</option>
                        <option value="Semestral">Semestral</option>
                        <option value="Trimestral">Trimestral</option>
                        <option value="Mensual">Mensual</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Día del mes de vencimiento</label>
                    <input class="form-control" type="number" v-model="documentoAEditar.dia_vencimiento_documentacion" max="31" id="">
                </div>
                <div v-if="documentoAEditar.tipo_vencimento_documentacion=='Anual'" class="form-group">
                    <label for="">Mes de vencimiento</label>
                    <select class="form-control" v-model="documentoAEditar.mes_vencimiento_documentacion" id="">
                        <option value="0">---</option>
                        <option value="1">Enero</option>
                        <option value="2">Febrero</option>
                        <option value="3">Marzo</option>
                        <option value="4">Abril</option>
                        <option value="5">Mayo</option>
                        <option value="6">Junio</option>
                        <option value="7">Julio</option>
                        <option value="8">Agosto</option>
                        <option value="9">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>
                <div style="display: flex; gap: 5px;">
                    <input type="checkbox" v-model="documentoAEditar.avisar_vencimiento_documentacion" v-bind:true-value="1" v-bind:false-value="0">
                    <span>Avisar</span>
                </div>
                <div v-if="documentoAEditar.avisar_vencimiento_documentacion == '1'" style="display: grid; gap: 10px;">
                    <div class="form-group">
                        <label for="">Días de antelación para aviso</label>
                        <input class="form-control" type="number" max="365" v-model="documentoAEditar.dias_aviso_vencimiento_documentacion" id="">
                    </div>
                    <div class="form-group">
                        <label for="">Asunto email</label>
                        <input class="form-control" type="text" v-model="documentoAEditar.asunto_aviso_documentacion" id="">
                    </div>
                    <div class="form-group">
                        <label for="">Texto email</label>
                        <textarea class="form-control" v-model="documentoAEditar.texto_aviso_documentacion" id=""></textarea>
                    </div>
                    <p> Palabras claves: {fecha_vencimiento} {nombre_documento} </p>
                </div>
                <div>
                    <button @click="guardarEditarDocu" class="btn btn-sm btn-light" style="border: 1px solid grey;">Guardar</button>
                </div>
            </div>
        </Dialog>
        <section class="content-header" style="width: 60%;">
            <div class="row mb-2">
                <h3>Documentación requerida</h3>
            </div>
        </section>
        <section class="content" style="width: 60%;">
            <div style="display: flex; justify-content: space-between; align-items: end;">
                <div class="row ml-0">
                    <input type="text" v-model="buscador" placeholder="Buscador">
                    <button @click="obtenerDocumentacion" class="btn btn-sm btn-light" style="border: 1px solid grey; margin-left: 5px;">Buscar</button>
                </div>
                <div>
                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="nuevaDocu">Nueva documentación</button>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo Vcto.</th>
                        <th>Día Vcto.</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="docu in documentacion" :key="docu.id_documentacion">
                        <td>
                            {{ docu.nombre_documentacion }}
                        </td>
                        <td>
                            {{ docu.tipo_vencimento_documentacion }}
                        </td>
                        <td>
                            {{ docu.dia_vencimiento_documentacion }}
                        </td>
                        <td style="display: flex; justify-content: end; gap: 10px;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="editarDocu(docu)">Editar</button>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarDocu(docu)">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>    
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    data() {
        return {
            buscador: '',
            documentacion: [],
            documentoAEditar: '',
            visibleDialogEditar: false,
            nombreDocuNueva:'',
            tipoDocuNueva:'Sin vencimiento',
            diaDocuNueva:0,
            mesDocuNueva:0,
            avisarDocuNueva:false,
            diasAvisoDocuNueva:0,
            textoEmailDocuNueva:'',
            asuntoEmailDocuNueva:'',
            visibleDialogCrear:false,
        }
    },
    //  modulos/pwgsapi/index.php/estadospendientes/:id/
    //  modulos/pwgsapi/index.php/estadospendientes/0/
    methods: {
        async guardarNuevaDocu(){
            const api = new PwgsApi();
            var body = {};
            body.nombre_documentacion = this.nombreDocuNueva;
            body.tipo_vencimento_documentacion = this.tipoDocuNueva;
            body.dia_vencimiento_documentacion = this.diaDocuNueva;
            if(this.tipoDocuNueva == "Anual"){
                body.mes_vencimiento_documentacion = this.mesDocuNueva;
            }
            body.avisar_vencimiento_documentacion = this.avisarDocuNueva;
            if(this.avisarDocuNueva == "1"){
                body.dias_aviso_vencimiento_documentacion = this.diasAvisoDocuNueva;
                body.asunto_aviso_documentacion = this.asuntoEmailDocuNueva;
                body.texto_aviso_documentacion = this.textoEmailDocuNueva;
            }
            try{
                const resp = await api.post('documentacion-requerida', body);
                console.log(resp);
                setTimeout(() => {
                    this.obtenerDocumentacion();
                    this.cerrarDialogCrear();
                }, 500);     
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 2000 });
            }
        },
        async guardarEditarDocu(){
            console.log('GuardarEdit', this.documentoAEditar);
            const api = new PwgsApi();
            var body = {};
            body.nombre_documentacion = this.documentoAEditar.nombre_documentacion;
            body.tipo_vencimento_documentacion = this.documentoAEditar.tipo_vencimento_documentacion;
            body.dia_vencimiento_documentacion = this.documentoAEditar.dia_vencimiento_documentacion;
            if(this.documentoAEditar.tipo_vencimento_documentacion == "Anual"){
                body.mes_vencimiento_documentacion = this.documentoAEditar.mes_vencimiento_documentacion;
            }
            body.avisar_vencimiento_documentacion = this.documentoAEditar.avisar_vencimiento_documentacion;
            if(this.documentoAEditar.avisar_vencimiento_documentacion == "1"){
                body.dias_aviso_vencimiento_documentacion = this.documentoAEditar.dias_aviso_vencimiento_documentacion;
                body.asunto_aviso_documentacion = this.documentoAEditar.asunto_aviso_documentacion;
                body.texto_aviso_documentacion = this.documentoAEditar.texto_aviso_documentacion;
            }
            try{
                const resp = await api.put('documentacion-requerida/'+this.documentoAEditar.id_documentacion, body);
                console.log(resp);
                setTimeout(() => {
                    this.obtenerDocumentacion();
                    this.cerrarDialogEditar();
                }, 500);  
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 2000 });
            }
        },
        nuevaDocu(){
            this.visibleDialogCrear = true;
        },
        cerrarDialogCrear(){
            this.visibleDialogCrear = false;
        },
        async editarDocu(docu){
            this.documentoAEditar = docu;
            console.log(this.documentoAEditar);
            if(!this.documentoAEditar.tipo_vencimento_documentacion){
                this.documentoAEditar.tipo_vencimento_documentacion = "Sin vencimiento";
            }
            if(!this.documentoAEditar.dia_vencimiento_documentacion){
                this.documentoAEditar.dia_vencimiento_documentacion = "0";
            }
            this.visibleDialogEditar = true;
        },
        cerrarDialogEditar(){
            this.visibleDialogEditar = false;
        },
        async eliminarDocu(docu){
            const api = new PwgsApi();
            await api.delete('documentacion-requerida/'+docu.id_documentacion);
            this.obtenerDocumentacion();
        },
        async obtenerDocumentacion() {
            const api = new PwgsApi;
            const resp = await api.get('documentacion-requerida?buscador='+this.buscador);
            this.documentacion = resp.datos;
        },
    },
    mounted() {
        this.obtenerDocumentacion();
    }
}
</script>
<style>
</style>